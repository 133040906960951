import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import NavBar from "../element/NavBar";
import Footer from "../element/NewFooter";
import { Link, useParams } from "react-router-dom";
import ApiKey from "../api/ApiKey";
import HTMLReactParser from "html-react-parser";
import Cookies from "js-cookie";
import BaseApi from "../api/BaseApi";
import { useTranslation } from "react-i18next";
import Header from "../element/Header";
import NewJobCard from "../element/NewJobCard";
import VerifiedIcon from '@mui/icons-material/Verified';

const NewCompanyProfile = () => {
  const [companyDetails, setCompanyDetails] = useState([]);
  const [companyJobs, setCompanyJobs] = useState([]);
  const [totalJobs, setTotalJobs] = useState();
  const [loading, setLoading] = useState(false);
  const { slug } = useParams();
  const slugData = slug;
  const [t, i18n] = useTranslation("global");
  let curr = Cookies.get("curr");
  const tokenKey = Cookies.get("tokenClient");
  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");
  let captchaKey = Cookies.get("captchaKey");

  const [hoverColor, setHoverColor] = useState(false);

  const handleMouseEnter = () => {
    setHoverColor(true);
  };

  const handleMouseLeave = () => {
    setHoverColor(false);
  };

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + `/candidates/companyprofile/${slugData}`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        }
      );
      console.log(response);
      setCompanyDetails(response.data.response.userDetails);
      setCompanyJobs(response.data.response.jobDetails);

      setLoading(false);
    } catch (error) {
      console.log("Error getting company profile");
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getData();
  }, [window.location.pathname]);


  // Create a reference for the companyRelatedJobsListing div
  const jobListingRef = useRef(null);

  const handleScrollToJobs = () => {
    jobListingRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Header />
      <div className="newPolicy text-center">
        {/* <h1>{t("companyProfile.companyProfile")}</h1>
        <h6 className="text-muted mt-2">
          {" "}
          <Link to="/" style={{ color: "grey" }}>
            {t("navHeaders.home")}
          </Link>{" "}
          /{t("companyProfile.companyProfile")}
        </h6> */}
      </div>
      {loading ? (
        <div className="loader-container"></div>
      ) : (
        <>
          <div className="blogPageSection2 container">
            <div className="row">
              <div className="row">
                <div className="col-md-8 col-sm-12 ">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="mb-3">
                        <ul className="newCompanyProfile">
                          <div className="uppersection">
                            <div className="newCompanyProfileLeftSide">
                            <img src={companyDetails.profile_image} alt="" />
                            </div>
                            
                            <Link onClick={handleScrollToJobs} className ="vacanciesBtn btn btn-primary">
                              View All Vacanies
                            </Link>
                          </div>
                          <li className="newCompanyProfileRightSide">
                            {/* <h2 className="mb-2">
                              {companyDetails.company_name}{companyDetails.verify === 1 ? <span className="verified"><VerifiedIcon color="success" sx={{ fontSize: 28 }}/></span> : <></>}
                            </h2> */}
                          
                            <p>
                              {companyDetails.company_about &&
                                HTMLReactParser(companyDetails.company_about)}
                            </p>
                          </li>
                        </ul>
                      </div>

                      <div className="record-count">
                        <p>
                          <strong>{companyJobs.length}</strong> record(s) have
                          been found.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div id="companyRelatedJobsListing" ref={jobListingRef} className="row" >
                    {companyJobs ? (
                      companyJobs.map((i) => (
                        <>
                          <div className="col-md-12 col-sm-12">
                            <NewJobCard
                              title={i.title}
                              min_salary={i.min_salary}
                              max_salary={i.max_salary}
                              min_exp={i.min_exp}
                              created={i.created}
                              logo={i.logo}
                              company_name={i.company_name}
                              work_type={i.work_type}
                              slug={i.jslug}
                              cat_slug={i.cslug}
                              job_city={i.job_city}
                              desc={i.description}
                            />
                          </div>
                        </>
                      ))
                    ) : (
                      <div className="col-12">
                        <h3 className="text-center">
                          {t("searchJobPage.noJobsTxt1")}{" "}
                        </h3>
                        <h6 className="text-muted text-center mb-5 mt-3">
                          {t("searchJobPage.noJobsTxt2")}
                        </h6>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="promo-block">
                    <h2>Contact Information</h2>
                    <p>{companyDetails.address}</p>
                    <p>
                      <i class="fa fa-phone"></i> {companyDetails.contact}
                    </p>
                    <p>
                      <i class="fa fa-envelope"></i>{" "}
                      <Link to="" style={{ color: "#422778" }}>
                        {companyDetails.email_address}
                      </Link>
                    </p>
                    {/* <p>
                      <Link
                        to={companyDetails.url}
                        className="btn btn-primary"
                        target="_blank"
                      >
                        <i class="fa fa-globe"></i> View Micro Site
                      </Link>
                    </p> */}
                  </div>
                  <div className="promo-block">
                    <h2>Find your next job faster than ever!</h2>
                    <ul>
                      <li>
                        <img
                          src="/Images/checked_icon.png"
                          alt="icon"
                          className="img-fluid"
                        />
                        FREE online registration
                      </li>
                      <li>
                        <img
                          src="/Images/checked_icon.png"
                          alt="icon"
                          className="img-fluid"
                        />
                        Daily job alerts
                      </li>
                      <li>
                        <img
                          src="/Images/checked_icon.png"
                          alt="icon"
                          className="img-fluid"
                        />
                        Online profile &amp; CV (searchable by clients)
                      </li>
                      <li>
                        <img
                          src="/Images/checked_icon.png"
                          alt="icon"
                          className="img-fluid"
                        />
                        Large client &amp; agency database
                      </li>
                    </ul>
                    <p>
                      {!tokenKey && (
                        <Link to="/user/register/jobseeker" className="btn btn-primary ">
                          Register now
                        </Link>
                      )}
                    </p>
                  </div>

                  <div className="promo-block">
                    <h2>Maximize your chances!</h2>
                    <p className="text-customize">
                      Ensure that your online CV is up to date as employers are
                      always searching our online CV database of candidates.
                    </p>
                    <p>
                      <Link
                        to="/candidates/myaccount"
                        className="btn btn-primary "
                      >
                        Modify your profile
                      </Link>
                    </p>
                  </div>

                  <div className="promo-block">
                    <h2>Need assistance?</h2>
                    <p className="text-customize">
                      A member of our staff is ready to help you with any
                      questions you may have.
                    </p>
                    <p>
                      <Link to="/contact" className="btn btn-primary">
                        Contact us for a fast response
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default NewCompanyProfile;
