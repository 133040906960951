import React, { useEffect, useState } from "react";
import Footer from "./NewFooter";
import { Link } from "react-router-dom";
import NavBar from "./NavBar";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import Cookies from "js-cookie";
import HTMLReactParser from "html-react-parser";
import { useTranslation } from "react-i18next";
import Header from "./Header";

const TermsConditions = () => {
  const [pageContent, setPageContent] = useState([]);
  const [loading, setLoading] = useState(false);
  const currentLanguage = Cookies.get("selectedLanguage") || "en";
  const [t, i18n] = useTranslation("global");

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        BaseApi + `/page/staticpage/terms-and-conditions`,
        { language: currentLanguage }
      );
      setLoading(false);
      setPageContent(response.data.response);
      const metaTitle = document.querySelector('meta[name="title"]');
        console.log(metaTitle.content, "metaTitle");
        if (metaTitle) {
          metaTitle.content = response.data.response.meta_tile;
        }
        // console.log("hi");
        const metaDescription = document.querySelector('meta[name="description"]');
        console.log(metaDescription.content, "metaDescription");
        if (metaDescription) {
          metaDescription.content = response.data.response.meta_description;
        }
  
        const metaKeywords = document.querySelector('meta[name="keywords"]');
        if (metaKeywords) {
          metaKeywords.content = response.data.response.meta_keyword;
        }
    } catch (error) {
      setLoading(false);
      console.log("Cannot get data of terms and condition page");
    }
  };

  useEffect(() => {
    getData();
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* <NavBar /> */}
      <Header />
      <div className="privacyPolicy">
        <div className="text-center PPSection1">
          <h1 className="">{pageContent.page_title}</h1>
          <h6 className="text-muted fw-normal">
            {" "}
            <Link to="/" style={{ color: "grey" }}>
              {t("navHeaders.home")}
            </Link>{" "}
            /{pageContent.page_title}
          </h6>
        </div>
        <div className="container">
          <div className="row">
            <div className="">
              <div className="upperPart">
                <p>
                  {pageContent.page_description &&
                    HTMLReactParser(pageContent.page_description)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsConditions;
